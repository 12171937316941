import React, { useContext, useEffect } from "react";
import { useNavigate } from "../../components/Link/Link";
import { LoginContext } from "../../components/LoginProvider/LoginProvider";
import { LoggedInHomePage, LoginPage } from "../../pages";
import Loading from "../../components/Loading/Loading";

const BrazePreferenceCenterRedirectPage = () => {
  const { loading: loginLoading, error: loginError, user, loggedIn } = useContext(LoginContext);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!loginLoading) {
        if (loggedIn && user?.userId) {
          try {
            // https://github.com/dietdoctor/firebase-node-cloud-functions/blob/main/functions/controllers/brazePreferenceCenter.js
            const response = await fetch(
              `${
                process.env.GATSBY_FIREBASE_CLOUD_FUNCTION_URL
              }/brazePreferenceCenter?userId=${encodeURIComponent(user.userId)}`
            );
            const data = await response.json();
            return (window.location.href = data.preference_center_url);
          } catch (error) {
            console.error(new Error("Failed fetching preference center url from cloud function"));
            return navigate({
              to: LoggedInHomePage,
            });
          }
        } else {
          if (loginError) console.error("loginError ", loginError);
          console.error(new Error("Couldn't find logged in user"));
          return navigate({
            to: LoginPage,
            query: {
              redirectTo: document.location.href,
            },
          });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginLoading, loggedIn]);

  return <Loading />;
};

export default React.memo(BrazePreferenceCenterRedirectPage);
